/* eslint-disable import/no-unresolved */
import React from 'react'
import { compose, withProps } from 'recompose'

// Components
import googleMapsSettings from 'services/GoogleMapsSettings'

// Image
import pin from 'img/pin.svg'

// Third Party
import styled from 'styled-components'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

const StyledGoogleMaps = styled.section``

const Title = styled.h2`
  font-size: ${(props) => props.theme.font.size.subheading};
  color: ${(props) => props.theme.color.secondary};
`

const GoogleMapsComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     */
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDwiS-BFz1vkdzv0w13546jbx8pY-zpXFU&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `536px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: any) => (
  <GoogleMap
    options={{
      styles: googleMapsSettings,
      fullscreenControl: false,
      controlSize: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
    }}
    defaultZoom={14}
    defaultCenter={{ lat: 52.023888, lng: 5.142992 }}
    yesIWantToUseGoogleMapApiInternals
  >
    {props.isMarkerShown && (
      <Marker
        defaultAnimation="drop"
        icon={pin}
        position={{ lat: 52.023888, lng: 5.142992 }}
      />
    )}
  </GoogleMap>
))

const GoogleMaps = () => (
  <StyledGoogleMaps className="mb-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 pb-lg-5 pb-3 d-flex justify-content-end">
          <Title>Waar we zitten.</Title>
        </div>
      </div>
    </div>
    <div className="w-100 h-100 position-relative">
      <GoogleMapsComponent isMarkerShown />
    </div>
  </StyledGoogleMaps>
)

export default GoogleMaps
